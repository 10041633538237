import React from 'react';
import { Switch, Route} from 'react-router-dom';
import * as ROUTES from './routes';
import DFSDevice from '../dfsmon/DFSDevice';

import LandingPage from '../layout/LandingPage';
import AllDfsHits from '../Logs/AllDfsHits';
import RebootedDevices from '../Logs/RebootedDevices';
import LogSummary from '../Logs/LogSummary';
import SignUp from '../auth/SignUp';
import SSIDMONDevices from '../ssidmon/SSIDMONDevices';
import SSIDMonHistory from '../ssidmon/SSIDMonHistory';
import SSIDMONConfigIssues from '../ssidmon/SSIDMONConfigIssues';
import UserSetting from '../appSetting/UserSetting';
import CompanySetting from '../appSetting/CompanySetting';
import EthernetMon from '../ethernet/EthernetMon';
import Stations from '../ethernet/Stations';
import FrequencyMap from '../frequencyMap/FrequencyMap'




const CustomeRoutes = (props) => (
		<Switch>
			<Route exact path={ROUTES.HOME_PAGE} component={LandingPage} />
			<Route exact path={ROUTES.DFS_MON_DEVICES} component={DFSDevice} />
			<Route exact path={ROUTES.DFS_MON_LOGS} component={LogSummary} />
			<Route exact path={ROUTES.DFS_MON_HITS} component={AllDfsHits} />
			<Route exact path={ROUTES.DFS_MON_REBOOTED} component={RebootedDevices} />
			<Route exact path={ROUTES.SIGN_UP} component={SignUp} />
			<Route exact path={ROUTES.USERS} component={UserSetting} />
			<Route exact path={ROUTES.COMPANY} component={CompanySetting} />
			<Route exact path={ROUTES.SSID_MON_DEVICES} component={SSIDMONDevices} />
			<Route exact path={ROUTES.SSID_MON_HISTORY} component={SSIDMonHistory} />
			<Route exact path={ROUTES.SSID_MON_CONFIG_ISSUES} component={SSIDMONConfigIssues} />
			<Route exact path={ROUTES.ETHERNET_MON_AP} component={EthernetMon} />
			<Route exact path={ROUTES.ETHERNET_MON_STATIONS} component={Stations} />
			<Route exact path={ROUTES.FREQUENCY_MAP} component={FrequencyMap} />
		</Switch>
	
)




export default CustomeRoutes;