import React , {useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// import classes from "./style.css";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';




const CompanyDetail = ({ open, handleClose, action, company,hasAdminRight }) => {
    const [currentCompany, setCompany] = useState({});
    const dispatch = useDispatch()

    useEffect(() => {
        setCompany({...company, updateApi: false})
      }, []);

    const handleFieldChange = (event) => {
        if(["active","updateApi"].includes(event.target.name)) {
            return setCompany({ ...currentCompany, [event.target.name] : !currentCompany[`${event.target.name}`] })
        }
        return setCompany({ ...currentCompany, [event.target.name]:event.target.value })
    }
  

    const onClose = () => {
        dispatch(action(currentCompany))
        handleClose()
        return 
    }
  
    
    return (
        <div>
        <Dialog  disableEscapeKeyDown={true} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Company Detail</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <Grid container spacing={2} >
                <Grid  item xs={12} md={12} lg={12}>
                    <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
                        <ListItem  alignItems="flex-start">
                            <ListItemAvatar>
                             <Avatar alt={currentCompany.shortName} src={currentCompany.photoURL} />
                            </ListItemAvatar>
                            <ListItemText
                               primary={"Company"}
                                secondary={
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                    {currentCompany.shortName}
                                    </Typography>
                                }
                            />
                              <ListItemText
                               primary={"UISP Status"}
                                secondary={
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color={currentCompany.result ? "primary" : "secondary"}
                                    >
                                    {currentCompany.message}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                    </Grid>
                    <Grid  item xs={12} md={12} lg={6}>
                    <TextField disabled={!hasAdminRight} margin="dense" name="shortName" id="shortName" onChange={handleFieldChange}  value={currentCompany.shortName} label="Short Name" type="text" fullWidth  />
                    </Grid>
                    <Grid  item xs={12} md={12} lg={6}>
                    <TextField disabled={!hasAdminRight} margin="dense" name="BASE_URL" id="BASE_URL" onChange={handleFieldChange}  value={currentCompany.BASE_URL} label="Base URL" type="text" fullWidth  />
                    </Grid>
                    <Grid  item xs={12} md={12} lg={12}>
                    <TextField disabled={!hasAdminRight} margin="dense" name="location" id="location" onChange={handleFieldChange}  value={currentCompany.location} label="Location" type="text" fullWidth  />
                    </Grid>
                    <Grid  item xs={12} md={12} lg={12}>
                    <TextField disabled={!hasAdminRight || !currentCompany.updateApi} margin="dense" name="API_KEY" id="API_KEY" onChange={handleFieldChange}  value={currentCompany.API_KEY} label="API Key" type="text" fullWidth  />
                    </Grid>
                <br />
                <Grid  item xs={6} md={6} lg={6}>
                   Update API Key
                    <Switch
                        onChange={handleFieldChange}
                        label="Update Company"
                        name="updateApi" 
                        id="updateApi"
                        disabled={!hasAdminRight}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={currentCompany.updateApi}
                    />
                
                </Grid>
                <Grid  item xs={6} md={6} lg={6}>
                   Enable
                    <Switch
                        onChange={handleFieldChange}
                        label="Enable Company"
                        name="active" 
                        id="active"
                        disabled={!hasAdminRight}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={currentCompany.active}
                    />
                </Grid>
                </Grid>
                <br />
                <div style={{display : "flex"}}>
                    
                </div>
            </DialogContent>
            <DialogActions  style={{display : "flex"}}>
                <Button disabled={!hasAdminRight} onClick={onClose} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default CompanyDetail;