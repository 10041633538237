

import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import { Button  } from "@mui/material";
import { useSelector } from 'react-redux';
import MaterialTable from "material-table";
import tableIcons from '../../constants/MaterialTableIcons';
import UserDetail from './UserDetail';



const UserSetting = () => {
  //const dispatch = useDispatch()
  const  {users} = useSelector((state) => state.auth)
  const currentUser = useSelector((state) => state.auth.user)
  
  let companies = useSelector((state) => state.global.companies)
  companies = companies.map(company => company.shortName)
  const [user, setUser] = useState();
  
  const hasAdminRight = ["super_admin","admin"].includes(currentUser.role)
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);
  const handClick = (rowData) => {
    setUser(rowData)
    setOpenDeviceSetting(true)
  
  }
  const handleClose =()=> {
    setOpenDeviceSetting(false)
  }
  //  useEffect(() => {
  //   dispatch(getUsers())
  // }, []);
  const columns = [
    {
      title: "Avatar",
      field: "photoURL",
      render: (rowData) =>   <Avatar alt={rowData.firstName} src={rowData.photoURL} />
    },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
   // { title: "Company", field: "company"},
    { title: "Role", field: "role" },
    {
      title: "Action",
      field: "action",
      render: (rowData) =>(<Button onClick={() => handClick(rowData)}>Edit</Button>),
    },
    {
      title: "status",
      field: "status",
      render: (rowData) =>!rowData.disabled? <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />,
    },
  ];

  return (
  <React.Fragment>
   {openDeviceSetting && <UserDetail open={openDeviceSetting} handleClose={handleClose} user={user} companies={companies} />}
   {hasAdminRight? <MaterialTable
      icons={tableIcons}
      title="Users"
      columns={columns} 
      data={ users && users}
      options={{
        exportButton: true
      }}
    /> :
    <Alert severity="info">Sorry, you don't have admin rights</Alert>
     }
   </React.Fragment>
   );
};

export default UserSetting;