
import axios from 'axios';
// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    baseURL: 'https://us-central1-wisphero-221bb.cloudfunctions.net/app'//'http://localhost:5000/wisphero-221bb/us-central1/app'// 
    //
});
//https://us-central1-device-api-4156b.cloudfunctions.net/app
// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//Access-Control-Allow-Credentials

// Also add/ configure interceptors && all the other cool stuff

export default instance;