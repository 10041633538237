import React , {useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { updateUser } from '../../store/actions/authActions';
import classes from "./style.css";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';




const UserDetail = ({ open, handleClose, user, companies }) => {
    const [currentUser, setUser] = useState({});
    const [curCompanies, setCurCompanies] = React.useState([]);
    const dispatch = useDispatch()
   
    useEffect(() => {
        setUser(user)
        setCurCompanies(user.company)
      }, []);

      const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
        };

  
    const handleFieldChange = (event) => {
        if(event.target.name === 'disabled') {
            return setUser({ ...currentUser, disabled : !currentUser.disabled })
        }
        return setUser({ ...currentUser, [event.target.name]:event.target.value })
    }
    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setCurCompanies(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
  

    const onClose = () => {
        handleClose()
        const data = {
            ...currentUser,
            company: curCompanies
        }
        
        return dispatch(updateUser(data))
    }

    return (
        <div>
        <Dialog  disableEscapeKeyDown={true} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">User Detail</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <Grid container spacing={2} >
                <Grid  item xs={12} md={12} lg={12}>
                    <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
                        <ListItem  alignItems="flex-start">
                            <ListItemAvatar>
                             <Avatar alt={currentUser.firstName} src={currentUser.photoURL} />
                            </ListItemAvatar>
                            <ListItemText
                               primary={currentUser.fullName}
                                secondary={
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                    {currentUser.company}
                                    </Typography>
                                }
                            />
                            <ListItemText
                               primary={currentUser.phoneNumber || ""}
                                secondary={
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                    {currentUser.email}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                    </Grid>
                    <Grid  item xs={12} md={12} lg={6}>
                    <FormControl fullWidth >
                        <InputLabel id="company">Company</InputLabel>
                        <Select
                        labelId="company"
                        id="company"
                        multiple
                        value={curCompanies}
                        onChange={handleChange}
                        input={<OutlinedInput label="Company" />}
                        renderValue={(selected) => selected.join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {companies && companies.map((shortName) => (
                            <MenuItem key={shortName} value={shortName}>
                            <Checkbox checked={curCompanies.indexOf(shortName) > -1} />
                            <ListItemText primary={shortName} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    {/* <TextField  margin="dense" name="company" id="company" onChange={handleFieldChange}  value={currentUser.company} label="Company" type="text" fullWidth  /> */}
                    </Grid>
                    <Grid  item xs={12} md={12} lg={6}>
                    <FormControl fullWidth>
                        <InputLabel id="role">Role</InputLabel>
                        <Select
                        labelId="role"
                        id="role"
                        name="role" 
                        value={currentUser.role}
                        label="Role"
                        onChange={handleFieldChange}
                        >
                        <MenuItem value={"basic"}>Basic</MenuItem>
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"super_admin"}>Super Admin</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextField  margin="dense" name="role" id="role" onChange={handleFieldChange}  value={currentUser.role} label="Role" type="text" fullWidth  /> */}
                    </Grid>
                   
                </Grid>
                <br />
                <div className = {classes.autoReboot}>
                    Enable
                    <Switch
                        onChange={handleFieldChange}
                        label="Disable User"
                        name="disabled" 
                        id="disabled"
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={!currentUser.disabled}
                    />
                </div>
                <br />
                <div style={{display : "flex"}}>
                    
                </div>
            </DialogContent>
            <DialogActions  style={{display : "flex"}}>
                <Button onClick={onClose} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="secondary">
                    cancel
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default UserDetail;
