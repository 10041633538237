import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import LogoutIcon from '@mui/icons-material/Logout';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Alert from "@mui/material/Alert";
import AppSetting from '../appSetting';
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { authSignOut } from '../../store/actions/authActions';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";


 


function Header(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { onDrawerToggle } = props;
  const [openUserSetting, setOpenUserSetting] = useState(false);
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);
  const [openGetLogs, setOpenGetLogs] = useState(false);
  const device = useSelector((state) => state.device)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  const { firstName, lastName, fullName } = auth.user;
  const rebootDevices = device.rebooted;
  const dfsHits = device.hitDevices.filter(device => device.id);
  const uniqueDFSHits = [...new Set(dfsHits.map(device => device.id))].length
  const uniqueRebootDevices = [...new Set(rebootDevices.map(device => device.id))].length

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
		setOpenUserSetting(false);
 
	};

   const on_click_setting_btn = () => {
		 setOpenUserSetting(true);
	}

  const handleFeedBack = () => {
    setOpenDeviceSetting(false)
  }

  const handleOpenLogs = () => {
    setOpenGetLogs(true)
  }

  
  const handleFeedBackLogs = () => {
    setOpenGetLogs(false)
  }

  const handleOpen = () => {
    setOpenDeviceSetting(true)
  }
  const handleLogout = async(e) => {
   
    // history.push("/login");
    setError("")

    try {
      await logout()
      dispatch(authSignOut())
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }
 
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item xs>
            {error && <Alert variant="danger">{error}</Alert>}
            </Grid>
            <Grid item xs>
              <Typography color="inherit" >
               Welcome { fullName }
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="settings">
                <IconButton onClick={on_click_setting_btn} color="inherit">
                  <SettingsIcon  />
                </IconButton>
              </Tooltip>
              <AppSetting on_click_setting_btn={on_click_setting_btn}  handleClose = {handleClose} open = {openUserSetting}/>
            </Grid>
           
            <Grid item>
              <Tooltip title="DFS hits">
                <IconButton color="inherit">
                <Badge badgeContent={uniqueDFSHits} color="secondary">
                  <NotificationsIcon />
                </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title="Reboot Devices">
                <IconButton color="inherit">
                <Badge badgeContent={uniqueRebootDevices} color="error">
                  <NotificationsIcon />
                </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
            <Tooltip title="Logout">
              <IconButton onClick={handleLogout} color="inherit" sx={{ p: 0.5 }}>
               <LogoutIcon />
              </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};



export default Header;