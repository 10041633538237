import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import { getEthernetmon } from "../../store/actions/deviceActions";
import DeviceSettingDialog from '../deviceSetting';




const columns = [
 // { field: 'id', headerName: 'ID', width: 1 },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 220,
  },
  {
    field: 'role',
    headerName: 'Function',
    width: 100,
    sortable: true,
    sortingOrder:['asc'],
  },
  {
    field: 'currentSpeed',
    headerName: 'Current Speed',
    type: 'number',
    sortable: true,
    sortingOrder:['asc'],
    width: 120,
  },
  {
    field: 'cableSnr',
    headerName: 'SNR',
    width: 80,
    sortable: true,
    sortingOrder:['asc'],
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 120,
    sortable: true,
    sortingOrder:['asc'],
  },
  {
    field: 'errors',
    headerName: 'Errors',
    width: 100,
     sortable: true,
    sortingOrder:['asc'],
  },
   {
    field: 'plugged',
    headerName: 'Plugged',
    sortable: true,
    sortingOrder:['asc'],
    width: 110,
    renderCell: (params) => {
      return  params.row.plugged?  <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />  ;

    }
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      const onClick = (e) => {
      
        e.stopPropagation(); 
      };

      return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
    }
  },
];


 

export default function EthernetMon() {
  const dispatch = useDispatch()
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);


  let devices = useSelector((state) => state.device.ethernetmon) || []
  devices = devices.filter(device => device.role === 'ap')

//   useEffect(() => {
//     dispatch(getEthernetmon())
//   }, []);

  
    const onRebookAll = () => {
        return setOpenDeviceSetting(true)
    }

    const handleFeedBack = () => {
        setOpenDeviceSetting(false)
    }
    
    const handleConfirm = () => {
        setOpenDeviceSetting(false)
        return  dispatch(getEthernetmon())

    }

 
  
  const handleRowClick = (params) => {
    if(params.field === "action") {
    } else {
     // console.log("params")
    }
   
  }


  return (
    <React.Fragment>
    <Button variant="contained" style={{marginRight: "30px",  marginBottom: "10px", }} onClick={onRebookAll}>Check Now</Button>

    <DeviceSettingDialog openDeviceSetting={openDeviceSetting}  msgType="info" handleFeedBack={handleFeedBack} question="Do you want to check for ethernet issues?" handleConfirmFunc={handleConfirm}  />
       <div style={{ height: 450, paddingTop: "10px", width: '100%' }}>
      <DataGrid
        rows={devices}
        columns={columns}
        pStationsSize={10}
        pageSize={25}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPStationsOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
       onCellClick={handleRowClick}
       components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No Issues found
          </Stack>
        )
      }}
      />
    </div>
    </React.Fragment>
  );
}
