import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button  } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from "material-table";
import tableIcons from '../../constants/MaterialTableIcons';
import { updateCompany, addCompany,addCompanyDevices,addDeviceLocation,addCompanySsidmon } from '../../store/actions/globalActions';
import CompanyDetail from './CompanyDetail';
import DeviceSettingDialog from '../deviceSetting';



const CompanySetting = () => {
  const dispatch = useDispatch()
  const  {companies} = useSelector((state) => state.global)
  const currentUser = useSelector((state) => state.auth.user)
  const [company, setCompany] = useState({});
  const [newcompany, setNewCompany] = useState({});
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);

  const [openAddCompany, setAddCompany] = useState(false);
const [openAddDevice, setAddDevice] = useState({dfsmon:false, ssidmon:false, location:false});

  const hasAdminRight = ["super_admin"].includes(currentUser.role)


  const handClick = (rowData) => {
    if (hasAdminRight) {
    const compData = (({ _id, shortName,location,BASE_URL,API_KEY,fullName,active,message,result }) => ({_id, shortName,location,BASE_URL,API_KEY,fullName,active, message, result}))(rowData);
    setCompany(compData)
    setOpenDeviceSetting(true)
    }
  
  }

  const handleAddDevice = (rowData, event) => {
    const compData = (({ _id, shortName,location,BASE_URL,API_KEY,fullName,active,message,result }) => ({_id, shortName,location,BASE_URL,API_KEY,fullName,active, message, result}))(rowData);
    setCompany(compData)
    if(event.target.id === "addDfsmon") {
      setAddDevice({...openAddDevice, dfsmon: true, ssidmon: false, location: false})
    } else if(event.target.id === "location") {
      setAddDevice({...openAddDevice, dfsmon: false, ssidmon: false, location: true})
    }
    else {
      setAddDevice({...openAddDevice, dfsmon: false, ssidmon: true, location: false})
    }
  }



  const handleClose =()=> {
    setOpenDeviceSetting(false)
    setAddCompany(false)
   // setAddDevice(false)
    setAddDevice({...openAddDevice, dfsmon: false, ssidmon: false, location: false})
  }

  const handleFeedBack = () => {
    //setAddDevice(false)
    setAddDevice({...openAddDevice, dfsmon: false, ssidmon: false, location: false})

  }
  
  const handleConfirm = () => {
    if(openAddDevice.dfsmon) {
      dispatch(addCompanyDevices(company))
    } else if(openAddDevice.location) {
      dispatch(addDeviceLocation(company))
    }
    else {
     dispatch(addCompanySsidmon(company))
    }
    return  setAddDevice({...openAddDevice, dfsmon: false, ssidmon: false, location: false})

  }

  //  useEffect(() => {
  //   dispatch(getAllCompanies())
  // }, []); 

  const columns = [
    { title: "Short Name", field: "shortName" },

    { title: "URL", field: "BASE_URL" },
    { title: "UISP", field: "message", 
    render: (rowData) => rowData.result? <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />,
  },
    {
      title: "Action",
      field: "action",
      render: (rowData) =>(<Button id="view" disabled={!hasAdminRight}  onClick={() => handClick(rowData)}>Edit</Button>),
    },
    {
      title: "Devices",
      field: "action",
      render: (rowData) =>(<Button id="addDfsmon" disabled={!hasAdminRight} onClick={(event) => handleAddDevice(rowData,event)}>Add</Button>),
    },
    {
      title: "SSID",
      field: "action",
      render: (rowData) =>(<Button id="addSsid" disabled={!hasAdminRight} onClick={(event) => handleAddDevice(rowData, event)}>Add</Button>),
    },
    {
      title: "Location",
      field: "action",
      render: (rowData) =>(<Button id="location" disabled={!hasAdminRight} onClick={(event) => handleAddDevice(rowData, event)}>Add</Button>),
    },
    {
      title: "status",
      field: "status",
      render: (rowData) => rowData.active? <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />,
    },
  ];



  return (
  <React.Fragment>
    <Grid container spacing={2} >
          {openDeviceSetting && <CompanyDetail hasAdminRight={hasAdminRight} actionText="Save" open={openDeviceSetting} handleClose={handleClose} action={updateCompany} company={company} />}
          {openAddCompany && <CompanyDetail hasAdminRight={hasAdminRight}  actionText="Add Company"  open={openAddCompany} handleClose={handleClose} action={addCompany} company={newcompany} />}
          <DeviceSettingDialog openDeviceSetting={openAddDevice.dfsmon || openAddDevice.ssidmon || openAddDevice.location}  msgType="error" handleFeedBack={handleFeedBack} question="Do you want to add devices?" handleConfirmFunc={handleConfirm}  />
        <Grid  item xs={6} md={6} lg={6}>
          {currentUser.role === "super_admin" && <Button onClick={()=> setAddCompany(true) }  variant="contained" color="primary">Add Company</Button>}
        </Grid>
      <Grid  item xs={12} md={12} lg={12}>
        <MaterialTable
            icons={tableIcons}
            title="Company"
            columns={columns} 
            data={companies && companies}
            options={{
              exportButton: true
            }}
          />
      </Grid>
    </Grid>
   </React.Fragment>
   );
};

export default CompanySetting;