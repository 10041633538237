import * as actionTypes from './actionTypes'
import axiosConfig from './axiosConfig';
import {differenceInMinutes} from 'date-fns'
import { getSsidmondevs } from './ssidmonActions';
import { mergeArrayObject} from './utility';
import { actionStart, actionFeedBack } from './globalActions';




export const dfsHitsSuccess  = (dfshits) => {
  return {
      type: actionTypes.DFS_HIT_SUCCESS,
      dfshits
  }
}

export const ethernetMonSuccess  = (devices) => {
  return {
      type: actionTypes.ETHERNETMON_SUCCESS,
      devices
  }
}

export const restartedDFSMON  = (devices) => {
  return {
      type: actionTypes.RESTARTED_DFSMON_DEVICES,
      devices
  }
}

export const deviceSuccess = (devices) => {
  return {
      type: actionTypes.DEVICE_SUCCESS,
      devices
  }
}

export const actionFail = (message) => {
  return {
    type : actionTypes.ACTION_FAIL,
    message
  }
}


export const checkDfsHit = (isCheckingDFSHits) => {
  return {
    type : actionTypes.IS_CHECKING_DFS_HIT,
    isCheckingDFSHits
  }
}

export const deviceRebooted = (devices) => {
  return {
      type: actionTypes.DEVICE_REBOOTED,
      devices
  }
}

export const apiSetting = (settings) => {
  return {
      type: actionTypes.API_SETTING_SUCCESS,
      settings
  }
}

export const deviceFail = (error) => {
  return {
      type: actionTypes.DEVICE_FAIL,
      error
  }
}


export const getdevices =  () => { 
  return async(dispatch, getState) => {
 
    try {
          
          dispatch(getSsidmondevs())
          const state = getState()
          const comp = state.auth.user.company
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const company = JSON.stringify(comp)
          const response = await  axiosConfig.get(`/devices/?company=${company}`)
         return dispatch(deviceSuccess(response.data.devices))
          //return dispatch(deviceSuccess(response.data.devices))
      
          //return dispatch(getDfsdevices())
        } catch (error) {
          let newDevice = []
          dispatch(deviceSuccess(newDevice))
    }
  }
}

const showNotification = (devices) => {
  const options = {
    body: `${devices} new dfs hits detected`,
    icon: "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?    auto=compress&cs=tinysrgb&dpr=1&w=500",
    dir: "ltr",
    vibrate: [200, 100, 200]
  };
  new Notification(`Push notification`, options)
}

export const getDfsdevices =  () => { 
  return async(dispatch, getState) => {
   
    try {
         // dispatch(getRebootDevices())
          const state = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const comp = state.auth.user.company
          const company = JSON.stringify(comp)
          const response = await  axiosConfig.get(`/dfshits/?company=${company}`)
          const dfshits =  mergeArrayObject(state.device.devices, response.data.dfshits)
          const dfsdevs = dfshits.length > 0 ? dfshits : state.device.hitDevices;
          const newDfshits = (dfsdevs.length - state.device.hitDevices.length) 
          // do this later, check if difference above is greater than zero b4 showing notification
          if(newDfshits > 0) {
            showNotification(newDfshits)
          }
          
          return dispatch(dfsHitsSuccess(dfsdevs))
        } catch (error) {
     
          let newDevice = []
          dispatch(dfsHitsSuccess(newDevice))
    }
  }
}



export const getEthernetmon =  () => { 
  return async(dispatch, getState) => {
    dispatch(actionStart("Running checks now, please be patient as this may take a while..."))
    try {
         // dispatch(getRebootDevices())
          const state = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const comp = state.auth.user.company
          const company = JSON.stringify(comp)
          const response = await  axiosConfig.get(`/ethernetmon/?company=${company}`)
          if(response.data.length > 0) {
            dispatch(actionFeedBack(`Success, Ethernetmon checked`))
          }
          const issues = response.data.length
          dispatch(actionFeedBack(`${ issues > 0 ?  `${issues} issues found` :"No issues found" }`))
       
          return dispatch(ethernetMonSuccess(response.data))
        } catch (error) {
          dispatch(actionFeedBack(`oops, an error occured, kindly try again`))
          let newDevice = []
          dispatch(dfsHitsSuccess(newDevice))
    }
  }
}


//socket.io-client
export const getCheckDfsHit = () => {
  return async(dispatch, getState) => {
    try {
      const state = getState()
      const company = state.auth.user.company
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
         
          const response = await   axiosConfig.get(`/dfshits/?company=${company}`)
         return dispatch(checkDfsHit(response.data.isCheckingDFSHits))
      } catch (error) {
        let newDevice = []
         return  dispatch(checkDfsHit(newDevice))

      }
  }
}

export const getRebootDevices = ({startDate, endDate}) => {
  return async(dispatch, getState) => {
    try {
          const state = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          dispatch(actionStart("Fetching devices..."))
          const comp = state.auth.user.company
          const company = JSON.stringify(comp)
          const start_d= JSON.stringify(startDate)
          const end_d= JSON.stringify(endDate)
          const response = await  axiosConfig.get(`/dfs-history/?company=${company}&startDate=${start_d}&endDate=${end_d}`)
          const dfshits = mergeArrayObject(state.device.devices, response.data.devices)
          //const devices = company === "ALL"? dfshits : dfshits.filter(device => device.company === company)
          dispatch(actionFeedBack(`Success, fetched devices`))
         return dispatch(deviceRebooted(dfshits))
      } catch (error) {
         return dispatch(actionFeedBack(`${error.message} `))

      }
  }
}

export const movedfstohistory = (devices) => {
  return async(dispatch, getState) => {
    try {
        const state = getState()
        const dfsmonDevices = state.device.hitDevices.filter(item => devices.includes(`${item.id}`))
        const data = dfsmonDevices.map(device => {
          return {
            id : device.deviceID,
            controlFrequency: device.controlFrequency,
            activeFrequency: device.activeFrequency,
            company: device.company
          }
        })
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
        
          dispatch(actionStart("Moving devices..."))
           const res = await axiosConfig.post('/move-to-history',data)
          dispatch(actionFeedBack(`Success,devices moved to History`)) 
        return dispatch(getDfsdevices())
      } catch (error) {
         return dispatch(actionFeedBack(`${error.message} `))

      }
  }
}

export const restartDFSmonDevice =  (devices) => { 
  return async(dispatch, getState) => {
       dispatch(actionStart('Restarting devices....'))
    try {
          const state = getState()
          const dfsmonDevices = state.device.hitDevices.filter(item => devices.includes(`${item.id}`))
          const data = dfsmonDevices.map(device => {
            return {
              id : device.deviceID,
              controlFrequency: device.controlFrequency,
              activeFrequency: device.activeFrequency,
              company: device.company
            }
          })
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const response = await  axiosConfig.post(`/restart-dfsmon`,data)
          const rebootResult = response.data.devices;
          //const devicesKeys = devices.map(device => device.id)
          const hitDevices =  state.device.hitDevices
          // const restartDevs = devices && devices.filter(device => device.restart)
          const restartDevsKeys = rebootResult && rebootResult.map(device => device.id)
          const updatedHitDevices = hitDevices.filter(device => !restartDevsKeys.includes(device.deviceID))
          // const restartedDevices = hitDevices && hitDevices.filter(device => devicesKeys.includes(device.id))
          // dispatch(restartedDFSMON(restartedDevices))
          // return  dispatch(dfsHitsSuccess(updatedHitDevices))
          dispatch(dfsHitsSuccess(updatedHitDevices))
          dispatch(actionFeedBack('Devices restarted'))
          return
        } catch (error) {
          console.log(error)
          dispatch(actionFeedBack('oop! an error occured....kindly try again'))
          return //dispatch(ssidmonRestartSuccess(newDevice))
    }
  }
}

export const getTimeDifference = (startTime, endTime) => {
    return Math.abs(differenceInMinutes(new Date(startTime), endTime))
}




