import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'


const initialGlobalState = {
    devices: [],
    ethernetmon:[],
    locations:[],
    displayFeedBack: false,
    loading: false,
    restartLoading: false,
    settings: {},
    hitDevices:[],
    rebooted:[],
    restarted:[],
    actionMessage: "",
    feedBackMsg: "",
    isCheckingDFSHits: true,
}

const deviceSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        restartLoading: false,
        actionMessage: "",
        devices: action.devices,
    })
}

const ethernetSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        restartLoading: false,
        actionMessage: "",
        ethernetmon: action.devices,
    })
}


const dfsHitsStart = (state, action) => {
    return updateObject(state, {
        isCheckingDFSHits: action.isCheckingDFSHits,
    })
}


const dfsmonRestart = (state, action) => {
    return updateObject(state, {
        restarted: action.devices,
        restartLoading: false
    })
}


const actionStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        restartLoading: true,
        actionMessage: action.message
    })
}

const confirmActionFeed = (state, action) => {
    return updateObject(state, {
        loading: false,
        restartLoading: false,
        displayFeedBack: false,
        feedBackMsg: ""
    })
}

const actionFeedBack = (state, action) => {
    return updateObject(state, {
        loading: false,
        restartLoading: false,
        feedBackMsg: action.message,
        displayFeedBack: true
    })
}

const actionClearData = (state, action) => {
    return updateObject(state, initialGlobalState)
}

// const actionFail = (state, action) => {
//     return updateObject(state, {
//         loading: false,
//         actionMessage: action.message
//     })
// }

const devRebooted = (state, action) => {
    return updateObject(state, {
        loading: false,
        restartLoading: false,
        actionMessage: "",
        rebooted: action.devices,
    })
}

//LOCATION_SUCCESS
const hitDeviceSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        restartLoading: false,
        actionMessage: "",
        hitDevices: action.dfshits,
        isCheckingDFSHits: false
    })
}

const locationSuccess = (state, action) => {
    return updateObject(state, {
        locations: action.locations,
    })
}

const apiSettingSuccess = (state, action) => {
    return updateObject(state, {
        settings: action.settings,
    })
}

const deviceReducer = (state=initialGlobalState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_START:
            return actionStart(state, action)
        case actionTypes.AUTH_LOGOUT:
            return actionClearData(state, action)
        case actionTypes.DEVICE_SUCCESS:
            return deviceSuccess(state, action)
        case actionTypes.API_SETTING_SUCCESS:
            return apiSettingSuccess(state, action)
        case actionTypes.DFS_HIT_SUCCESS:
            return hitDeviceSuccess(state, action)
        case actionTypes.ACTION_FEEDBACK:
            return actionFeedBack(state, action)
        case actionTypes.CONFIRM_ACTION_FEEDBACK:
            return confirmActionFeed(state, action)
        case actionTypes.DEVICE_REBOOTED:
            return devRebooted(state, action)
        case actionTypes.IS_CHECKING_DFS_HIT:
            return dfsHitsStart(state, action)
        case actionTypes.RESTARTED_DFSMON_DEVICES:
            return dfsmonRestart(state, action)
        case actionTypes.ETHERNETMON_SUCCESS:
            return ethernetSuccess(state, action)
        case actionTypes.LOCATION_SUCCESS:
            return locationSuccess(state, action)
        default:
            return state;
    }
}


//export{initialGlobalState, globalReducer};

export default deviceReducer;