//import moment from 'moment';
export const getItemsInFirstThatAreInSecond = (firstArray, secondArray) => {
    const keysInSecond = secondArray.map(item => item.id) 
    const newArray = firstArray.filter(item => keysInSecond.includes(item.id))
    return newArray
}

export const mergeArrayObject = (devices, hitDevices) => {
    const data = hitDevices.map(device => {
        const devDetail = devices.find(dev => dev.id === device.id)
        //if (!devDetail.id) return false
        const mergedDev = {
            ...devDetail,
            activeFrequency: device.activeFrequency,
            controlFrequency : device.controlFrequency,
            createdAt : device.createdAt,//moment(device.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
            id: device._id,
            deviceID: device.id
        }
        return mergedDev
    })
    
    const newData = data.filter(device => Object.keys(device).length > 5)
  
    return newData
}

export const mergeDeviceAndLocations = (devices, locations) => {
    const data = locations.map(location => {
        try {
            const devDetail = devices.find(dev => dev.id === location.id)
            const mergedDev = {
                ...location,
                activeFrequency: devDetail.activeFrequency,
                modelName: devDetail.modelName,
                deviceName: devDetail.name,
            }
            return mergedDev
        } catch (error) {
            return {}
        }
        
    })
    
    const newData = data.filter(device => Object.keys(device).length > 8)
  
    return newData
}