import React, {useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
// import { Button } from '@mui/material';
// import ErrorIcon from '@mui/icons-material/Error';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import {StateContext} from '../../constants/Context';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';


const columns = [
  {
    field: 'siteName',
    headerName: 'Site Name',
    width: 160,
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 220,
    editable: true,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 120,
  },
  {
    field: 'stationsCount',
    headerName: 'Stations',
    type: 'number',
    width: 100,
    editable: true,
  },
  {
  field: 'timestamp',
  headerName: 'DFS Hit Time',
  type: 'Date',
  width: 200,
  editable: false,
  sortable: true
  },
  {
    field: 'rebootTime',
    headerName: 'Reboot Time',
    type: 'Date',
    width: 200,
    editable: false,
    sortable: true
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
        
          e.stopPropagation(); 
        };
  
        return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
      }
    },

];




export default function LogSummary() {


  const hitDevices = useSelector((state) => state.device)

  useEffect(() => {

    let dev = []
    // Object.entries(devices).map((device) => {
   
    //  dev.push(device[1])
    // })
    // setAlldfs(dev)
    // dispatch(getDfsdevices())
    //
  },[]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={hitDevices}
        columns={columns}
        pStationsSize={10}
        pageSize={25}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPStationsOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        autoPageSize={true}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No rows in DataGrid
            </Stack>
          )
        }}
       // loading={hitDevices.length > 0? false : true}
      />
    </div>
  );
}
