import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import { useSelector } from 'react-redux';

export default function ProtectedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  const auth = useSelector((state) => state.auth)
  return (
    <Route
      {...rest}
      render={props => {
        return currentUser && auth.isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }}
    ></Route>
  )
}
// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { isLoaded, isEmpty } from "react-redux-firebase";
// import { useSelector } from "react-redux";



// const PrivateRoute = ({ children, ...remainingProps }) => {
//   const auth = useSelector(state => state.firebase.auth);
//   return (
//     <Route
//       {...remainingProps}
//       render={({ location }) =>
//         isLoaded(auth) && !isEmpty(auth) ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/home",
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };
// export default PrivateRoute;