import React , {useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker'; 
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Slider from '@mui/material/Slider';
import { Button } from '@mui/material';
import Switch from '@mui/material/Switch';
import { getCheckDfsHit } from '../../store/actions/deviceActions'
import { addSsidMon } from '../../store/actions/ssidmonActions'
import classes from "./style.css";
import { useDispatch } from 'react-redux';

const marks = [
    {
      value: 5,
      label: '5 min',
    },
    {
      value: 10,
      label: '10 min',
    },
    {
        value: 15,
        label: '15 min',
      },
    {
      value: 20,
      label: '20 min',
    },
    {
        value: 25,
        label: '25 min',
      },
      {
        value: 30,
        label: '30 min',
      },
  ];
  

const AppSetting = ({ open, handleClose, on_click_setting_btn }) => {

   
    const [rebootInterval, setRebootIntervalValue] = useState(parseInt(localStorage.getItem("rebootInterval") || "1"));
    const [startBootTime, setStartBootTime] = useState(new Date());
    const [stopBootTime, setStopBootTime] = useState(new Date());
    const [autoReboot, setAutoReboot] = useState(false);
    const dispatch = useDispatch()

    const handleChange = (event) => {
        setAutoReboot(event.target.checked);
      };

    const onClose = () => {
        const settings = {rebootInterval, startBootTime, stopBootTime, autoReboot}
        handleClose();
       dispatch(getCheckDfsHit(settings))
       
    }

    const handleChangeRefreshIntervalSlider = (event, newValue) => {
		setRebootIntervalValue(newValue);

	};

  const addZiplinkSSID = () => {
    dispatch(addSsidMon())
  }

   
    
    return (
        <div>
        <Dialog  disableEscapeKeyDown={true} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">APP SETTINGS</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/* To subscribe to this website, please enter your email address here. We will send updates
                    occasionally. */}
                </DialogContentText>
                {/* <TextField error = {apiKeyError.isError} helperText = {apiKeyError.message}  autoFocus margin="dense" id="api_key" label="API KEY" type="text" fullWidth inputProps= {{ref:apiKeyRef}} defaultValue = {apiKey} />
                <TextField error = {baseUrlError.isError} helperText = {baseUrlError.message} margin="dense" id="baseurl" label="BASE URL" type="text" fullWidth inputProps = {{ref:baseURLRef}} defaultValue = {baseUrl} /> */}
                <br />
                <div className = {classes.timerSlider}>
                Refresh Wait Time<Slider step={1} marks={marks} value={rebootInterval} onChange={handleChangeRefreshIntervalSlider} aria-labelledby="continuous-slider" valueLabelDisplay="auto" max={30} min={0}/>
                </div>
                <div className = {classes.autoReboot}>
                    Auto Reboot
                    <Switch
                        checked={autoReboot}
                        onChange={handleChange}
                        label="Auto Restart"
                        inputProps={{ 'aria-label': 'controlled' }}
                />
                </div>
                <br />
                <div style={{display : "flex"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div  style={{paddingRight: "15px"}}>
                     <TimePicker
                        label="Start Reboot Time"
                        id={"stopBootTime"}
                        inputProps = {{ref:startBootTime}} 
                        value={startBootTime}
                        onChange={setStartBootTime}
                        minDateTime={new Date()}
                        renderInput={(params) => <TextField   id={"startBootTime"} {...params} />}
                    />
                    </div>
                    <div>
                     <TimePicker
                        label="Stop Reboot time"
                        id={"stopBootTime"}
                        value={stopBootTime}
                        inputProps = {{ref:stopBootTime}} 
                        onChange={setStopBootTime}
                        renderInput={(params) => <TextField {...params} />}
                        minDateTime={new Date()}
                    />
                    </div>
                    </LocalizationProvider> 
                </div>
            </DialogContent>
            <DialogActions  style={{display : "flex"}}>
                <Button onClick={onClose} color="primary">
                    Save
                </Button>
                <Button onClick={handleClose} color="secondary">
                    cancel
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default AppSetting;