import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



const columns = [
  {
    field: 'siteName',
    headerName: 'Site Name',
    width: 150,
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 150,
    editable: true,
  },
  {
    field: 'activeSSID',
    headerName: 'Active SSID',
    width: 230,
  },
  {
    field: 'primarySSID',
    headerName: 'Prim SSID',
    width: 230,
  },
  {
    field: 'secondarySSID',
    headerName: 'Secon SSID',
    width: 160,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 100,
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
      
        e.stopPropagation(); 
      };

      return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
    }
  },
  {
    field: 'restart',
    headerName: 'Status',
    description: 'Status',
    sortable: true,
    sortingOrder:['asc'],
    width: 120,
    renderCell: (params) => {
      return  (params.row.restart) ?  <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />  ;

    }
  },
];




export default function SSIDMONConfigIssues() {
  
  const ssidmondevs = useSelector((state) => state.ssidmon.ssidmonDevices) || {}
  const devices = ssidmondevs.filter(device => ((device.activeSSID === device.primarySSID) && (device.activeSSID === device.secondarySSID)) )

  return (
    <React.Fragment>
    <div style={{ height: 450, paddingTop: "10px", width: '100%' }}>
      <DataGrid
        rows={devices}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25, 50, 100]}
        pStationsSize={10}
        rowsPerPStationsOptions={[100]}
        checkboxSelection
        disableSelectionOnClick
        autoPageSize={false}
        loading={devices.length > 0? false : true}
      />
    </div>
    </React.Fragment>
  );
}
