import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';
import { BrowserRouter } from  "react-router-dom";


// const rrfConfig = {
//   userProfile: "users",
//   useFirestoreForProfile: true,
// };



const store = createStore(rootReducer,compose( 
 applyMiddleware(thunk))
  )



  

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
     <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>,
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
