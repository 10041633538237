import * as actionTypes from './actionTypes';
import axiosConfig from './axiosConfig';
import { actionStart, actionFeedBack,getAllCompanies } from './globalActions';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    }
}

export const authFeedReset = () => {
    return {
        type: actionTypes.AUTH_FEEDBACK_RESET
    }
}

export const resetFeed = () => {
    return {
        type: actionTypes.RESET_FEEDBACK
    }
}

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user,
    }
}

export const usersSuccess = (users) => {
    return {
        type: actionTypes.AUTH_USERS_SUCCESS,
        users
    }
}

export const usersFail = () => {
    return {
        type: actionTypes.AUTH_USERS_FAIL,
        
    }
}

export const authStatusUpdate = (updateFeed) => {
    return {
        type: actionTypes.AUTH_STATUS_SUCCESS,
        updateFeed
    }
}

export const authResetSuccess = (resetFeed) => {
    return {
        type: actionTypes.AUTH_RESET_SUCCESS,
        resetFeed
    }
}

export const authSignUpFeedBack = (feedBack) => {
    return {
        type: actionTypes.AUTH_SIGNUP,
        feedBack
    }
}


export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

  

export const updateUser = (user) => {
    return async(dispatch, getState) => {
        dispatch(actionStart('updating user details'))
        try {
            const state = getState()
            const newUser = state.auth.users.filter(stateUser => stateUser.email === user.email)[0]
            axiosConfig.defaults.headers = {
                "Authorization": `Bearer ${state.auth.token}`,
            }
            if(newUser.disabled && !user.disabled){
                await dispatch(activateUser(user))
            }
           
            const res = await  axiosConfig.put(`/update-users`,user)
            const result = res.data.updateUser.acknowledged
            const users = state.auth.users.map(curUser => {
                const currentUser = (curUser.uid === user.uid && result)? user : curUser;
                return currentUser
            })
            // await dispatch(activateUser(users))
            await dispatch(usersSuccess(users))
            return dispatch(actionFeedBack('User details updated successfully'))
        } catch (error) {
            return dispatch(actionFeedBack(error.message))
        }
    }
}

export const activateUser = (user) => {
    return async(dispatch, getState) => {
        dispatch(actionStart('activating user'))
        try {
            const state = getState()
           // const newUsers = state.auth.users.filter(item => users.includes(item._id))
            axiosConfig.defaults.headers = {
                "Authorization": `Bearer ${state.auth.token}`,
            }
        
            const res = await  axiosConfig.put(`/activate-user`,user)
            const result = res.data.updateUser.acknowledged
            const users = state.auth.users.map(curUser => {
                const currentUser = (curUser.uid === user.uid && result)? user : curUser;
                return currentUser
            })
            await dispatch(usersSuccess(users))
            return dispatch(actionFeedBack('User activated successfully'))
        } catch (error) {
            return dispatch(actionFeedBack(error.message))
        }
    }
}


export const getUsers = () => {
    return async(dispatch, getState) => {
        //const { role, company } = credential;
        try {
            const state = getState()
            const comp = state.auth.user.company
            axiosConfig.defaults.headers = {
              "Authorization": `Bearer ${state.auth.token}`,
            }
            const company = JSON.stringify(comp)
           // await axiosConfig.get(`/users`,company)

           if (["super_admin"].includes(state.auth.user.role)) {
            const response =await  axiosConfig.get(`/all-users`)
            await dispatch(usersSuccess(response.data))
          } else {
            const response =await  axiosConfig.get(`/users/?company=${company}`)
            await dispatch(usersSuccess(response.data))
          }

           
            return
          } catch (error) {   
             
            return dispatch({type: 'AUTH_UPDATE_USER_DETAIL'})
          }
    }
}


export const authLogin = (user) => {
    return async(dispatch, getState) => {
        const { email, password } = user;
        try {
            const response = await axiosConfig.post(`/login`,{
                email,
                password
            })
           
            await dispatch(authSuccess(response.data))
            return
          } catch (error) {   
             
            return dispatch(authFail("Incorrect email or password"))
          }
    }
}

export const authGoogleLogin = (user) => {
    return async(dispatch, getState) => {
        const { email, id } = user;
        dispatch(authStart())
        try {
            const res = await axiosConfig.post(`/google-login`,{
                email,
                id
            })
            res.status === 201? dispatch(authFail(res.data.message)) : dispatch(authSuccess(res.data))
            dispatch(getAllCompanies())
            if (["admin", "super_admin"].includes(res.data.role)) {
                dispatch(getUsers())
            }
            return 
          } catch (error) {   
            return dispatch(authFail("User does not exist, kindly sign up"))
          }
    }
}

export const authGoogleSignup = (user) => {
    return async(dispatch, getState) => {
        const { email, id } = user;
        dispatch(authStart())
        try {
            const res = await axiosConfig.post(`/google-signup`,user)
            return  res.status === 201? dispatch(authFail(res.data.message)) : dispatch(authSuccess(res.data))
            
          } catch (error) {   
            return dispatch(authFail("User does not exist, kindly sign up"))
          }
    }
}



export const checkLoginState = (user) => {
    return async(dispatch, getState) => {
        const { email, id } = user;
        try {
            const res = await axiosConfig.post(`/google-login`,{
                email,
                id
            })
            return res.status === 201? dispatch(authFail(res.data.message)) : dispatch(authSuccess(res.data))
            
          } catch (error) {   
            return dispatch(authFail("Incorrect email or password"))
          }
    }
}


export const authSignUp = (user) => {
    return async(dispatch, getState,) => {
        try {
            const response = await axiosConfig.post(`/signup`,{
                email: user.email,
                password: user.password,
                firstName : user.firstName,
				lastName : user.lastName,
				role : user.role,
                company: user.company,
                department: user.department,
            })
            
            return dispatch(authSuccess(response.data))
      
          } catch (error) {
            return dispatch(authFail(error.message))
          }
    }   
}


export const authSignOut = () => {
    return async(dispatch, getState) => {
        try {
            // await axiosConfig.get(`/logout`)
            
            return dispatch(authLogout())
        } catch (error) {
            return dispatch(authFail(error.message))
        }
	}    
}
