import React , {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker'; 
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Button  from '@mui/material/Button';
import { useDispatch } from 'react-redux';



  

const DateRangeSelector = ({ open, handleClose, actionToDispatch }) => {

    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const dispatch = useDispatch()

    const onClose = () => {
        handleClose();
       dispatch(actionToDispatch({startDate, endDate}))
       
    }


    return (
        <div>
        <Dialog  disableEscapeKeyDown={true} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Select Date Range</DialogTitle>
            <DialogContent>
            <br />
                <div style={{display : "flex"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div  style={{paddingRight: "15px"}}>
                     <DateTimePicker
                        label="Start Date"
                        id={"endDate"}
                        inputProps = {{ref:startDate}} 
                        value={startDate}
                        onChange={setstartDate}
                        //minDateTime={new Date()}
                        renderInput={(params) => <TextField   id={"startDate"} {...params} />}
                    />
                    </div>
                    <div>
                     <DateTimePicker
                        label="End Date"
                        id={"endDate"}
                        value={endDate}
                        inputProps = {{ref:endDate}} 
                        onChange={setendDate}
                        renderInput={(params) => <TextField {...params} />}
                       // minDateTime={new Date()}
                    />
                    </div>
                    </LocalizationProvider> 
                </div>
            </DialogContent>
            <DialogActions  style={{display : "flex"}}>
                <Button onClick={onClose} color="primary">
                    Get Devices
                </Button>
                <Button onClick={handleClose} color="secondary">
                    cancel
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default DateRangeSelector;