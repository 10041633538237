import React from 'react';
import { Switch, Route} from 'react-router-dom';
import * as ROUTES from './routes';
import HomePage from '../layout/HomePage';
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import ProtectedRoute from '../ProtectedRoute';
import ForgotPassword from '../auth/ForgotPassword';


const BaseRouter = (props) => (

		<Switch>
			<Route exact path={ROUTES.SIGN_IN} component={SignIn} />
			<Route exact path={ROUTES.SIGN_UP} component={SignUp} />
			<Route exact path={ROUTES.FORGOT_PASSWOD} component={ForgotPassword} />
			<ProtectedRoute path={ROUTES.HOME_PAGE} component={HomePage}  />
			
		</Switch>
	
)



export default BaseRouter;