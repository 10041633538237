export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_RESET_SUCCESS = 'AUTH_RESET_SUCCESS';
export const AUTH_FEEDBACK_RESET = 'AUTH_FEEDBACK_RESET';
export const AUTH_STATUS_SUCCESS = 'AUTH_STATUS_SUCCESS'
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER = 'AUTH_USER';
export const DEVICE_SUCCESS = 'DEVICE_SUCCESS';
export const DEVICE_FAIL = 'DEVICE_FAIL';
export const DEVICE_REBOOTED = 'DEVICE_REBOOTED';
export const AUTH_USERS_SUCCESS = 'AUTH_USERS_SUCCESS';
export const AUTH_USERS_FAIL = 'AUTH_USERS_FAIL';
export const AUTH_UPDATE_USER_DETAIL = 'AUTH_UPDATE_USER_DETAIL';

export const COMPANY_FAIL = 'COMPANY_FAIL';
export const ADD_TO_CARTITEM_SUCCESS = 'ADD_TO_CARTITEM_SUCCESS';
export const ADD_TO_CARTITEM_FAIL = 'ADD_TO_CARTITEM_FAIL';
export const RESET_FEEDBACK = 'RESET_FEEDBACK';
export const IS_CHECKING_DFS_HIT = 'IS_CHECKING_DFS_HIT';

export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';

export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const CONFIRM_ACTION_FEEDBACK = 'CONFIRM_ACTION_FEEDBACK';
export const ACTION_START = 'ACTION_START';
export const ACTION_FAIL = 'ACTION_FAIL';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FEEDBACK = 'ACTION_FEEDBACK';
export const API_SETTING_SUCCESS = 'API_SETTING_SUCCESS';
export const DFS_HIT_SUCCESS = 'DFS_HIT_SUCCESS';
export const SSIDMON_SUCCESS = 'SSIDMON_SUCCESS';
export const SSIDMON_UPDATE_SUCCESS = 'SSIDMON_UPDATE_SUCCESS';
export const SSIDMON_RESTART_SUCCESS = 'SSIDMON_RESTART_SUCCESS';
export const SSIDMON_HISTORY_SUCCESS = 'SSIDMON_HISTORY_SUCCESS';
export const RESTARTED_DFSMON_DEVICES = 'RESTARTED_DFSMON_DEVICES';
export const ETHERNETMON_SUCCESS = 'ETHERNETMON_SUCCESS';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';


