import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import { confirmActionFeedBack } from '../store/actions/globalActions'


const FeedBack = () => {
    const {displayFeeed,isActionStart, actionMessage} = useSelector((state) => state.global)
    const dispatch = useDispatch()

    const handleComfirm = () => {
        dispatch(confirmActionFeedBack())
    }
    
    return (
        <div>
        <Dialog  open={displayFeeed} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
                <DialogContentText>
                   {actionMessage}
                </DialogContentText>
              
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={isActionStart} onClick={handleComfirm} color="primary">
                    Ok
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default FeedBack;