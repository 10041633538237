import { React, useMemo } from "react";
import { useSelector } from 'react-redux';
import { GoogleMap, MarkerF, useJsApiLoader,MarkerClusterer } from "@react-google-maps/api";


  const getCenter = arr => {
    const lats = arr.map(loc => loc.position.lat)
    const logs = arr.map(loc => loc.position.lng)

    const logsmid = Math.floor(logs.length / 2)
    const logsnums = [...logs].sort((a, b) => a - b);
    const logCenter = arr.length % 2 !== 0 ? logsnums[logsmid] : (logsnums[logsmid - 1] + logsnums[logsmid]) / 2;

    const latsmid = Math.floor(lats.length / 2)
    const latsnums = [...lats].sort((a, b) => a - b);
    const latCenter = arr.length % 2 !== 0 ? latsnums[latsmid] : (latsnums[latsmid - 1] + latsnums[latsmid]) / 2;

    return {lat:latCenter, lng: logCenter }
  };



const FrequencyMap = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
  });

  // const [center, setCenter] = useState({})

  const locations = useSelector((state) => state.device.locations) || []
  const devsLocs = locations && locations.map(dev => {
    const locs = {
      position:{
      lat: dev.latitude,
      lng: dev.longitude
    },
    deviceName: dev.deviceName,
    freq: dev.activeFrequency,
    id: dev.id
  }
    return locs
  })


  const MapContainerStyles = {
    width: "100%",
    height: '100vh',
    padding: "0px",
    margin:"0px"
  };

  const center = useMemo(() => (devsLocs && getCenter(devsLocs)), [devsLocs.length]);
  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };


  if (!isLoaded || devsLocs.length == 0 ) return <div>Loading...</div>;

  return (
      <GoogleMap zoom={10} options={options} center={center || {lat: 0, lng:0}} mapContainerStyle={MapContainerStyles}>
       <MarkerClusterer >
          {(clusterer) =>
            devsLocs && devsLocs.map((location) => (
              <MarkerF key={location.id} label={location.deviceName} position={location && location.position} clusterer={clusterer} />
            ))
          }
        </MarkerClusterer>
    
      </GoogleMap>
  );
};

export default FrequencyMap;