export const HOME_PAGE = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/login';
export const USERS = '/setting/users';
export const COMPANY = '/setting/company';
export const FORGOT_PASSWOD = '/forgot-password'

export const DFS_MON = '/dfs-mon';
export const SSID_MON = '/ssid-mon';
export const ETHERNET_MON_STATIONS = '/ethernet-mon/stations';
export const ETHERNET_MON_AP = '/ethernet-mon/ap';
export const ETHERNET_MON_ROUTERS = '/ethernet-mon/routers';
export const AIRTIME_MON = '/airtime-mon';
export const POWER_MON = '/power-mon';

export const DFS_MON_REBOOTED = '/dfs-mon/history';
export const DFS_MON_HITS = '/dfs-mon/hits';
export const DFS_MON_DEVICES = '/dfs-mon/devices';

export const DFS_MON_LOGS = '/dfs-mon/logs';
export const SSID_MON_DEVICES = '/ssid-mon/devices';
export const SSID_MON_HISTORY = '/ssid-mon/history'
export const SSID_MON_CONFIG_ISSUES = '/ssid-mon/config'
// export const ETHERNET_MON = '/ethernet-mon';
// export const AIRTIME_MON = '/airtime-mon';
// export const POWER_MON = '/power-mon';

export const LOG_SUMMARY = '/log-summary';
export const DEVICES = '/devices';
export const SERVICES = '/services';
export const PASSWORD_FORGET = '/pw-forget';
export const BID_DETAIL = '/bidDetail/:id'
export const VEHICLE_DETAIL = '/vehicle/:id'
export const BID_DETAIL_VIEW = '/bid/:id'
export const CHECKOUT_GUEST = '/checkout-guest'
export const PAYMENT = '/payment'
export const HOME = '/home'
export const FREQUENCY_MAP = '/frequency-map'