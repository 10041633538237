import React, { useRef, useState,useEffect } from "react"
import { Box, Button, Card, Alert, Grid, CardContent  } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory, Redirect } from "react-router-dom"
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authGoogleLogin,authSignOut } from '../../store/actions/authActions'
import { useSelector, useDispatch } from 'react-redux';
import Modal from './Modal';
import './Signin.css';


const theme = createTheme();

export default function SignIn() {
  // const emailRef = useRef()
  // const passwordRef = useRef()
  const { login, logInWithGoogle, currentUser,logout } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [useEmail, setEmail] = useState(false);
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  // const history = useHistory()

  const handleUseEmail = () => {
    setEmail(true)
    setError("")
  }
  const signOut = () => {
    logout()
    dispatch(authSignOut())
  }

  useEffect(() => {
    setError("")
      let isCurrentUser =currentUser? true : false
      if(isCurrentUser) {
        const {id,email } = currentUser && currentUser;
        dispatch(authGoogleLogin({email, id}))
      } 
 
   return () => {
     isCurrentUser = false
   }
  
  }, []);


  async function handleSubmit(e) {
    e.preventDefault()
    
    try {
      setError("")
      signOut()
      setLoading(true)
      const user = await logInWithGoogle()
      const {id,email } = user && user.user;
      dispatch(authGoogleLogin({email, id}))
    } catch(error) {
      setError(error.message)
      setEmail(false)
    }
    return setLoading(false)
  }
 
 if(auth.isAuthenticated && currentUser) return <Redirect to={'/'} />

  return (
    <ThemeProvider theme={theme}>
    <Modal open={(loading || auth.loading)? true : false}  msg ="loading..." />
    <Grid container component="main" maxWidth="xs" className="signin"
        sx={{
         height: '100vh' ,
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
        //  backgroundImage: 'url(https://pre00.deviantart.net/d4a7/th/pre/i/2013/276/9/8/mountain_background_with_waterfall_by_burtn-d6p1keb.jpg)',
        //  backgroundRepeat: 'no-repeat',         
         backgroundSize: 'cover',
         backgroundPosition: 'center',
       }}
      >
      <CssBaseline />
      <Box
        sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      <Card sx={{
            maxWidth: 400,
            padding:2,
           
          }}>
        <CardContent  sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom:4
          }}>
          {(error || auth.error) && <Alert  severity="error">{error || auth.error}</Alert>}
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
         <Button
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
            >
              With Google
            </Button>
             <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Grid container  sx={{ mt: 2, mb: 2 }}>
              <Grid  sx={{marginRight: 1}} item xs>
              <div >
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              </Grid>
              <Grid item>
              <div className="w-100 text-center mt-2">
               Need an account? <Link to="/signup">Sign Up</Link>
              </div>
              </Grid>
            </Grid> 
          </Box>
        </CardContent>
      </Card>
      </Box>
      </Grid>
      </ThemeProvider>
  )
}
