
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



const production = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PRODUCTION,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PRODUCTION,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_PRODUCTION,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PRODUCTION,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PRODUCTION,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PRODUCTION,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_PRODUCTION
}

// const development = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEVELOPMENT,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEVELOPMENT,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_DEVELOPMENT,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEVELOPMENT,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEVELOPMENT,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEVELOPMENT,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID_DEVELOPMENT
// }

//const config = process.env.REACT_APP_PRODUCTION_ENVIRONMENT? production : development;

const app = firebase.initializeApp(production)

export const auth = app.auth()

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default app








// firebase.initializeApp(firebaseConfig);
// firebase.firestore().enablePersistence();

//const firebase = initializeApp(firebaseConfig);

