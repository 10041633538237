import React, {useEffect} from 'react';
import BaseRouter from './components/routes/BaseRouter';
import { useDispatch } from 'react-redux';
import Layout from './constants/Layout';
import { AuthProvider } from "./contexts/AuthContext";
import { getDfsdevices} from './store/actions/deviceActions';


const App = () => {
  const dispatch = useDispatch()
 
 
  // const handleOnIdle = event => {
  //   console.log('user is idle', event)
  //   console.log('last active', getLastActiveTime())
  // }

  // const handleOnActive = event => {
  //   console.log('user is active', event)
  //   console.log('time remaining', getRemainingTime())
  // }

  // const handleOnAction = event => {
  //   console.log('user did something', event)
  // }

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 3600,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500
  // })
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
   
  }, []);
 
  useEffect(() => {
    const interval = setInterval(() => {
      
       dispatch(getDfsdevices())
    }, 30000);
  
    return () => clearInterval(interval);
  }, []);

  // const showNotification = () => {
  //   new Notification('Hey there, wisp hero is showing notifications')
  // }

  return (
    <div>
          {/* <PrivateRoute path = "/home">
             <HomePage />
          </PrivateRoute>
          <Route path = "/">
            <SignIn />
          </Route> */}
          <Layout>
            <AuthProvider>
              <BaseRouter />
            </AuthProvider>
          </Layout>
        
   
    </div>
  );
}

export default App;
