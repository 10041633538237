import React,{ useEffect, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getDfsdevices,restartDFSmonDevice,movedfstohistory } from '../../store/actions/deviceActions';
import DeviceSettingDialog from '../deviceSetting';




const columns = [
  {
    field: 'siteName',
    headerName: 'Site Name',
    width: 160,
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 220,
    editable: true,
  },
  {
    field: 'modelName',
    headerName: 'Model Name',
    width: 230,
  },
  {
    field: 'controlFrequency',
    headerName: 'Cont. Freq',
    type: 'number',
    width: 100,
    editable: true,
  },
  {
    field: 'activeFrequency',
    headerName: 'Active Freq',
    width: 120,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 120,
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      const onClick = (e) => {
      
        e.stopPropagation(); 
      };

      return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
    }
  },

    {
    field: "action",
    headerName: "Action",
    sortable: true,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); 
      };

      return <Button disabled={params.row.status? false : true} onClick={onClick}>Reboot</Button>;
    }
  },
  {
  field: 'createdAt',
  headerName: 'DFS Hit Time',
  type: 'Date',
  width: 200,
  editable: false,
  sortable: true,
  // valueGetter: (params) => {
  //   return moment(params.row.createdAt).format('MMMM Do YYYY, h:mm:ss a');
  // }
  renderCell: (params) => {
    return   moment(params.row.updatedAt).format('MM/DD/YYYY, h:mm:ss a');
  }
  },
];




export default function AllDfsHits() {
  
  const devices = useSelector((state) => state.device.hitDevices) || {}
  const loading = useSelector((state) => state.device.isCheckingDFSHits)
  const devs = useSelector((state) => state.device.devices) || []
  const [selectedDeives, setSelectedDevice] = useState([])
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);
  const [openMoveSetting, setOpenMoveSetting] = useState(false);
  const dispatch = useDispatch()
  const onCellSelect = (params) => {
    setSelectedDevice([...params])
  }

  const onRebookAll = (event) => {
    if(selectedDeives.length === 0) return
    if(event.target.id === "reboot") {
            setOpenMoveSetting(false)
      return  setOpenDeviceSetting(true)
    } else {
          setOpenDeviceSetting(false)
      return setOpenMoveSetting(true)
    }
 
  }

  const handleFeedBack = () => {
    setOpenDeviceSetting(false)
    setOpenMoveSetting(false)
  }
  
  const handleConfirm = () => {
    if(openDeviceSetting) {
      dispatch(restartDFSmonDevice(selectedDeives))
    } else{
      dispatch(movedfstohistory(selectedDeives))
    }
    setOpenDeviceSetting(false)
    setOpenMoveSetting(false)
    return

  }

  const handleMoveToHistory = () => {
    setOpenDeviceSetting(false)
    
    return  dispatch(movedfstohistory(selectedDeives))

  }


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //      dispatch(getDfsdevices())
  //   }, 30000);
  
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <React.Fragment>
    <Button variant="contained"  id="reboot" style={{marginRight: "30px",  marginBottom: "10px", }} onClick={onRebookAll}>Reboot</Button>
    <Button variant="contained" id="moveToHistory" style={{marginRight: "30px",  marginBottom: "10px", }} onClick={onRebookAll}>Move to History</Button>
    {/* <Alert severity="info">Last Refreshed @ { devs.length > 0 && moment(devs[0].updatedAt).format('MMMM Do YYYY, h:mm:ss a')} -------- Next Refresh @ {devs.length > 0 && moment(moment(devs[0].updatedAt)).add(15, 'minutes').format('MMMM Do YYYY, h:mm:ss a')}</Alert> */}
    <DeviceSettingDialog openDeviceSetting={openDeviceSetting}  msgType="error" handleFeedBack={handleFeedBack} question="Do you want to reboot selected devices?" handleConfirmFunc={handleConfirm}  />
    <DeviceSettingDialog openDeviceSetting={openMoveSetting}  msgType="error" handleFeedBack={handleFeedBack} question="Do you want to move selected device?" handleConfirmFunc={handleConfirm}  />
    <div style={{ height: 450, paddingTop: "10px", width: '100%' }}>
    <DataGrid
      rows={devices}
      columns={columns}
      pStationsSize={10}
      pageSize={25}
      rowsPerPageOptions={[25, 50, 100]}
      rowsPerPStationsOptions={[10]}
      checkboxSelection
      disableSelectionOnClick
      autoPageSize={true}
      loading={loading}
      onSelectionModelChange={onCellSelect}
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No DFS hits found
          </Stack>
        )
      }}
      //value={device.settings}
    />
  </div>
    </React.Fragment>
  );
}
