import * as actionTypes from './actionTypes'
import axiosConfig from './axiosConfig';
import { getdevices,getDfsdevices } from './deviceActions';
import { mergeDeviceAndLocations } from './utility';


export const addCompanySuccess = (companies) => {
  return {
      type: actionTypes.COMPANY_SUCCESS,
      companies
  }
}



export const actionStart = (message) => {
  return {
    type : actionTypes.ACTION_START,
    message
  }
}

export const actionFeedBack = (message) => {
  return {
      type: actionTypes.ACTION_FEEDBACK,
      message
  }
}

export const devLocations = (locations) => {
  return {
      type: actionTypes.LOCATION_SUCCESS,
      locations
      
  }
}

export const actionFail = (message) => {
  return {
    type : actionTypes.COMPANY_FAIL,
    message
  }
}

export const confirmActionFeedBack = () => {
  return (dispatch, getState) => {
    dispatch({type : actionTypes.CONFIRM_ACTION_FEEDBACK})
  }
}


export const addCompany = (company) => {
  return async(dispatch, getState) => {
      dispatch(actionStart('Adding new company'))
      try {
        const state = getState()
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
          const res = await axiosConfig.post(`/company`,{company})
          let newCompanies = state.global.companies
          res.data.company && newCompanies.push(res.data.company)
         
          dispatch(addCompanySuccess(newCompanies))
         return  dispatch(actionFeedBack('company added successfully'))
        } catch (error) {   
          dispatch(actionFeedBack('coop! action failed kindly try again later'))
          return dispatch(actionFeedBack('company added successfully'))
        }
  }
}


export const addCompanyDevices = (company) => {
  return async(dispatch, getState) => {
      dispatch(actionStart('Adding company devices'))
      try {
        const state = getState()
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
       
          const res = await axiosConfig.post(`/add-devices`,{company})
          
          // let newCompanies = state.global.companies
          // res.data.company && newCompanies.push(res.data.company)
        //  console.log(res.data)
          // dispatch(addCompanySuccess(newCompanies))
         return  dispatch(actionFeedBack('company devices added successfully'))
        } catch (error) {   
           
          return dispatch(actionFeedBack('adding dfsmon devices unsuccessful'))
        }
  }
}


export const addDeviceLocation = (company) => {
  return async(dispatch, getState) => {
      dispatch(actionStart('Adding devices locations'))
      try {
        const state = getState()
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
          const devices = state.device.devices.filter(device => device.company === company.shortName)
          const data = devices.map(device => {
            const newData = {
              id : device.id,
              company: company,
            }
            return newData
          })
          
          const res = await axiosConfig.post(`/add-device-location`,{data})
          
          // // let newCompanies = state.global.companies
          // // res.data.company && newCompanies.push(res.data.company)
          console.log(devices)
          // dispatch(addCompanySuccess(newCompanies))
         return  dispatch(actionFeedBack('devices locations added successfully'))
        } catch (error) {   
           console.log(error)
          return dispatch(actionFeedBack('adding dfsmon devices unsuccessful'))
        }
  }
}


export const getDeviceLocation = () => {
  return async(dispatch, getState) => {
      try {
          const state = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const comp = state.auth.user.company
          const company = JSON.stringify(comp)
          const res = await axiosConfig.get(`/get-device-location/?company=${company}`)
         

          const locations = mergeDeviceAndLocations(state.device.devices,res.data.locations)
  
          return dispatch(devLocations(locations))
         
        } catch (error) {   
           console.log(error)
          return
        }
  }
}

export const addCompanySsidmon = (company) => {
  return async(dispatch, getState) => {
      dispatch(actionStart('Adding ssidmon devices'))
      try {
        const state = getState()
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
       
          const res = await axiosConfig.post(`/add-ssidmon`,{company})
          
          // let newCompanies = state.global.companies
          // res.data.company && newCompanies.push(res.data.company)
          
          // dispatch(addCompanySuccess(newCompanies))
         return  dispatch(actionFeedBack('ssidmon devices added successfully'))
        } catch (error) {   
           
          return dispatch(actionFeedBack('adding ssidmon devices unsuccessful'))
        }
  }
}



export const updateCompany = (company) => {

  return async(dispatch, getState) => {
      dispatch(actionStart('updating company details'))
      try {
        const state = getState()
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
          const res = await axiosConfig.post(`/update-company`,{company})
          const result = res.data.acknowledged
          
          const companies = state.global.companies.map(curCompany => {
              const currentComp = (curCompany._id === company._id && result)? company : curCompany;
              return currentComp;
          })
      
          dispatch(addCompanySuccess(companies))
          return dispatch(actionFeedBack('Company details updated successfully'))
        } catch (error) {   
         
          return dispatch(actionFeedBack('Company details update unsuccess'))
        }
  }
}

export const getAllCompanies = () => {
  return async(dispatch, getState) => {
      try {
        const state = getState()
        const comp = state.auth.user.company
        const company = JSON.stringify(comp)
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
          
          if (["super_admin"].includes(state.auth.user.role)) {
            const response = await axiosConfig.get('/all-companies')
            
            dispatch(addCompanySuccess(response.data))
          } else {
            const response = await axiosConfig.get(`/companies/?company=${company}`)
           
            dispatch(addCompanySuccess(response.data))
          }
         
         // 
          await dispatch(getdevices())
          await dispatch(getDfsdevices())
          await dispatch(getDeviceLocation())
          return dispatch(getAllCompaniesStatus())
        } catch (error) {
          return  dispatch(actionFeedBack('Company details not retrieved'))
        }
  }
}

export const getAllCompaniesStatus = () => {
  return async(dispatch, getState) => {
      try {
        const state = getState()
        const comp = state.global.companies.map(comp => comp.shortName)
        const company = JSON.stringify(comp)
        axiosConfig.defaults.headers = {
          "Authorization": `Bearer ${state.auth.token}`,
        }
       
          const response = await axiosConfig.get(`/companies-status/?company=${company}`)
       
         
          typeof response.data === "object" ? dispatch(addCompanySuccess(response.data)) :dispatch(actionFeedBack('Kindly check the API key for each company, if any is not [object Object], update the API key'))
          return dispatch(getdevices())
        } catch (error) {   
         
          return  dispatch(actionFeedBack('Company details not retrieved'))
        }
  }
}

