import * as actionTypes from './actionTypes'
import axiosConfig from './axiosConfig';
import { actionStart, actionFeedBack } from './globalActions';
import { mergeArrayObject} from './utility';


export const ssidmonSuccess = (devices) => {
  return {
      type: actionTypes.SSIDMON_SUCCESS,
      devices
  }
}

export const ssidmonRestartSuccess = (devices) => {
  return {
      type: actionTypes.SSIDMON_RESTART_SUCCESS,
      devices
  }
}
export const ssidmonHistorySuccess = (devices) => {
  return {
      type: actionTypes.SSIDMON_HISTORY_SUCCESS,
      devices
  }
}

export const ssidmonUpdateSuccess = (devices) => {
  return {
      type: actionTypes.SSIDMON_UPDATE_SUCCESS,
      devices
  }
}

export const actionFail = (message) => {
  return {
    type : actionTypes.ACTION_FAIL,
    message
  }
}



export const getSsidmondevs =  () => { 
  return async(dispatch, getState) => {

    try {
          const state = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const comp = state.auth.user.company
          const company = JSON.stringify(comp)
          const response = await  axiosConfig.get(`/ssidmon-devices/?company=${company}`)
         
          await dispatch(ssidmonSuccess(response.data.devices))
          return dispatch(gethistoryssidmondevs())
        } catch (error) {
          let newDevice = []
          dispatch(ssidmonSuccess(newDevice))
    }
  }
}


export const gethistoryssidmondevs =  () => { 
  return async(dispatch, getState) => {
 
    try {
          const state = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const comp = state.auth.user.company
          const company = JSON.stringify(comp)
          const response = await  axiosConfig.get(`/ssidmon-history/?company=${company}`)
          const rebootdevs = mergeArrayObject(state.ssidmon.ssidmonDevices,response.data.devices)
          return dispatch(ssidmonHistorySuccess(rebootdevs))
        } catch (error) {
          let newDevice = []
          dispatch(ssidmonHistorySuccess(newDevice))
    }
  }
}

export const restartSsidmondevs =  (devices) => { 
  return async(dispatch, getState) => {
    dispatch(actionStart('Restarting devices....'))
    try {
          const state = getState()
        //   const ssidmonDevices = state.ssidmon.ssidmonDevices.filter((device) => {
        //     return devices.some(dbDevice => device.id.includes(dbDevice))
        // })
        const ssidmonDevices = state.ssidmon.ssidmonDevices.filter(item => devices.includes(item.id))
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${state.auth.token}`,
          }
          const response = await  axiosConfig.post(`/restart-ssidmon`,ssidmonDevices)
     
          dispatch(actionFeedBack('Devices restarted'))
          return dispatch(ssidmonRestartSuccess(response.data.devices))
          
        } catch (error) {
          console.log(error)
          dispatch(actionFeedBack('oop! an error occured....kindly try again'))
          let newDevice = []
          dispatch(ssidmonRestartSuccess(newDevice))
    }
  }
}


export const updateSsidmondevs =  () => { 
  return async(dispatch, getState) => {
    let newDevice = []
  
    dispatch(ssidmonSuccess(newDevice))
    try {
          const accessToken = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${accessToken.auth.token}`,
          }
          const response = await  axiosConfig.post(`/update-ssidmon`)
          return dispatch(ssidmonSuccess(response.data))
        } catch (error) {
         
          dispatch(ssidmonSuccess(newDevice))
    }
  }
}

export const addSsidMon =  () => { 
  return async(dispatch, getState) => {
    let newDevice = []
    dispatch(actionStart('adding devices....'))
    try {
          const accessToken = getState()
          axiosConfig.defaults.headers = {
            "Authorization": `Bearer ${accessToken.auth.token}`,
          }
          const response = await  axiosConfig.post(`/add-ssidmon`)
          dispatch(actionFeedBack('Devices added'))
          return //dispatch(ssidmonSuccess(response.data))
        } catch (error) {
         console.log(error)
         dispatch(actionFeedBack('oop! an error occured....kindly try again'))
          dispatch(ssidmonSuccess(newDevice))
    }
  }
}




