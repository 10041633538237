import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getdevices } from "../../store/actions/deviceActions";




const columns = [
 // { field: 'id', headerName: 'ID', width: 1 },
  {
    field: 'siteName',
    headerName: 'Site Name',
    width: 160,
  },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 220,
  },
  {
    field: 'modelName',
    headerName: 'Model Name',
    width: 160,
  },
  {
    field: 'stationsCount',
    headerName: 'Stations',
    type: 'number',
    width: 100,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 120,
  },
  {
    field: 'activeFrequency',
    headerName: 'Active Freq',
    width: 120,
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      const onClick = (e) => {
      
        e.stopPropagation(); 
      };

      return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
    }
  },
];


 

export default function DFSDevice() {
  const dispatch = useDispatch()


  const devices = useSelector((state) => state.device.devices) || []

  useEffect(() => {
    dispatch(getdevices())
    const interval = setInterval(() => {
       dispatch(getdevices())

    }, 120000);
    return () => clearInterval(interval);
  }, []);

 
  
  const handleRowClick = (params) => {
    if(params.field === "action") {
    } else {
     // console.log("params")
    }
   
  }


  return (
    <React.Fragment>
       <Alert severity="info">Last Refreshed @ { devices.length > 0 && moment(devices[0].updatedAt).format('MMMM Do YYYY, h:mm:ss a')} -------- Next Refresh @ {devices.length > 0 && moment(moment(devices[0].updatedAt)).add(15, 'minutes').format('MMMM Do YYYY, h:mm:ss a')}</Alert>
       <div style={{ height: 450, paddingTop: "10px", width: '100%' }}>
      <DataGrid
        rows={devices}
        columns={columns}
        pStationsSize={10}
        pageSize={25}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPStationsOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        loading={devices.length > 0? false : true}
       onCellClick={handleRowClick}
      />
    </div>
    </React.Fragment>
  );
}
