import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'


const initialGlobalState = {
    ssidmonDevices:[],
    ssidmonRestart:[],
    displayFeedBack: false,
    loading: false,
    ssidmonHistory:[],
    actionMessage: "",
    feedBackMsg: "",
    ssidRestartFeed:""
}

const ssidmonHistory = (state, action) => {
    return updateObject(state, {
        loading: false,
        actionMessage: "",
        ssidmonHistory: action.devices,
    })
}

const ssidmonDevices = (state, action) => {
    return updateObject(state, {
        loading: false,
        actionMessage: "",
        ssidmonDevices: action.devices,
    })
}

const ssidmonUpates = (state, action) => {
    return updateObject(state, {
        loading: false,
        actionMessage: "",
        ssidmonDevices: action.devices,
    })
}

const ssidmonRestart = (state, action) => {
    return updateObject(state, {
        loading: false,
        actionMessage: "",
        ssidmonRestart: action.devices,
    })
}

const actionStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        actionMessage: action.message
    })
}

const actionClearData = (state, action) => {
    return updateObject(state, initialGlobalState)
}


const ssidmonReducer = (state=initialGlobalState, action) => {
    switch(action.type) {
        case actionTypes.ACTION_START:
            return actionStart(state, action)
        case actionTypes.AUTH_LOGOUT:
            return actionClearData(state, action)
        case actionTypes.SSIDMON_SUCCESS:
            return ssidmonDevices(state,action);
        case actionTypes.SSIDMON_UPDATE_SUCCESS:
            return ssidmonUpates(state,action);
        case actionTypes.SSIDMON_HISTORY_SUCCESS:
            return ssidmonHistory(state, action)
        case actionTypes.SSIDMON_RESTART_SUCCESS:
            return ssidmonRestart(state, action)
        default:
            return state;
    }
}



export default ssidmonReducer;