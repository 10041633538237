import React,{ useEffect,useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getRebootDevices } from '../../store/actions/deviceActions';
import DateRangeSelector from './DateRangeSelector';



const columns = [
  {
    field: 'siteName',
    headerName: 'Site Name',
    width: 160,
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 220,
    editable: true,
  },
  {
    field: 'controlFrequency',
    headerName: 'Cont. Freq',
    type: 'number',
    width: 100,
    editable: true,
  },
  {
    field: 'activeFrequency',
    headerName: 'Active Freq',
    width: 120,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 120,
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      const onClick = (e) => {
      
        e.stopPropagation(); 
      };

      return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
    }
  },
  // {
  //   field: 'status',
  //   headerName: 'Status',
  //   description: 'Dfs hits',
  //   sortable: true,
  //   sortingOrder:['asc'],
  //   width: 110,
  //   renderCell: (params) => {
  //     return  params.row.reboot?  <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />  ;

  //   }
  // },
  {
  field: 'createdAt',
  headerName: 'DFS Hit Time',
  type: 'Date',
  width: 250,
  editable: true,
  sortable: true,
  // valueFormatter: (params) => {
   
  //   // first converts to JS Date, then to locale option through date-fns
  //   return moment(params.value).format('MM/DD/YYYY, h:mm:ss a');
  // },
  // valueGetter: (params) => {
    
  //  return new Date()// moment(params.row.createdAt).format('MM/DD/YYYY, h:mm:ss a');
  // },
  renderCell: (params) => {
    return   moment(params.row.createdAt).format('MM/DD/YYYY, h:mm:ss a');
  }
  },
];

export default function RebootedDevices() {
  const dispatch = useDispatch()
  //const state = useContext(StateContext)
  const [openUserSetting, setOpenUserSetting] = useState(false);
  const rebootDev = useSelector((state) => state.device.rebooted)
  const handleClose = () => {
		setOpenUserSetting(false);
 
	};
  const on_click_setting_btn = () => {
    setOpenUserSetting(true);
 }

 useEffect(() => {
  setOpenUserSetting(true);
}, []);
  // useEffect(() => {
  //   dispatch(getRebootDevices({}))
  //   const rebootID = setInterval(() => {
  //     const startDate = moment(moment(new Date())).subtract(7, 'days').format('YYYY-MM-DD')
  //     const endDate = moment(moment(new Date())).format('YYYY-MM-DD')
  //      dispatch(getRebootDevices({startDate, endDate}))
  //   }, 30000);
  //   return () => clearInterval(rebootID);
  // }, []);

  return (
    <React.Fragment>
    <React.Fragment>
      <Tooltip title="settings">
                <IconButton onClick={on_click_setting_btn} color="inherit">
                  <SettingsIcon  /> 
                </IconButton>
              </Tooltip>
              <DateRangeSelector actionToDispatch={getRebootDevices} handleClose = {handleClose} open = {openUserSetting}/>
    </React.Fragment>
    <div style={{ height: 400, width: '100%' }}> 
        <DataGrid
          rows={rebootDev}
          columns={columns}
          pStationsSize={10}
          pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPStationsOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          autoPageSize={true}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
               No data found for the period selected
              </Stack>
            )
          }}
          //loading={rebootDev.length > 0 ? false : true}
        />
      
      </div>
    </React.Fragment>
  );
}
