import React, { useRef, useState } from "react"
import { Box, Button, Card, Alert, Grid, TextField, CardContent  } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext"
import { Link } from "react-router-dom"
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const theme = createTheme();

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  return (
    <ThemeProvider theme={theme}>
    <Grid container component="main" maxWidth="xs"
        sx={{
         height: '100vh' ,
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         backgroundImage: 'url(https://pre00.deviantart.net/d4a7/th/pre/i/2013/276/9/8/mountain_background_with_waterfall_by_burtn-d6p1keb.jpg)',
         backgroundRepeat: 'no-repeat',         backgroundSize: 'cover',
        backgroundPosition: 'center',
       }}
        >
      <CssBaseline />
      <Box
        sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      <Card sx={{
            maxWidth: 400,
            padding:2,
           
          }}>
        <CardContent  sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom:4
          }}>
          {error && <Alert  severity="error">{error}</Alert>}
          {message && <Alert severity="info">{message}</Alert>}
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              inputRef={emailRef}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
              <div className="w-100 text-center mt-2">
                <Link to="/login">Login</Link>
              </div>
              </Grid>
              <Grid item>
              <div className="w-100 text-center mt-2">
               Need an account? <Link to="/signup">Sign Up</Link>
              </div>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      </Box>
      </Grid>
      </ThemeProvider>
  )
}