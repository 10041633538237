import React, { useRef, useState,useEffect } from "react"
import { Box, Button, Card, Alert, Grid, CardContent, } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authGoogleSignup, authSignOut } from '../../store/actions/authActions';
import Modal from './Modal';
import './Signin.css';


const theme = createTheme({
  textField:{paddingRight: 10 }
});

export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const firstNameRef = useRef()
  const dispatch = useDispatch()
  const lastNameRef = useRef()
  const companyRef = useRef()
  const [company, setComapny] = useState('');
  const { signup,logInWithGoogle, currentUser,logout } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const  {companies} = useSelector((state) => state.global)
  const auth = useSelector((state) => state.auth)

  const handleChange = (event) => {
    setComapny(event.target.value);
  };

  const signOut = async() => {
     await logout()
    dispatch( authSignOut())
  }

  useEffect(() => {
     signOut()
    return () => {
     signOut()
    }
  }, [])

  async function handleSubmit(e) {
    e.preventDefault()

    // if (firstNameRef.current.value === ""  || lastNameRef.current.value ==="" || companyRef.current.value === "") {
    //   return setError("All fields are required")
    // }  

    try {
      setError("")
      setLoading(true)
      signOut()
     // const user = await signup(emailRef.current.value, passwordRef.current.value)
     const user = await logInWithGoogle()
      const userDetail = {
        uid: user.user.uid,
        firstName: user.user.displayName.split(' ')[0],//firstNameRef.current.value,
        lastName: user.user.displayName.split(' ')[1],//lastNameRef.current.value,
        fullName: user.user.displayName,
        email: user.user.email,
        photoURL: user.user.photoURL,
        phoneNumber: user.user.phoneNumber,
        company: ["Plugins"]//companyRef.current.value
      }
      dispatch(authGoogleSignup(userDetail))
      //history.push("/")
    } catch(error) {
      setError(error.message)
    }

    setLoading(false)
  }
 // if(auth.isAuthenticated && currentUser) return <Redirect to={'/'} />


  return (
    <ThemeProvider theme={theme}>
    <Modal open={(loading || auth.loading)? true : false}  msg ="Creating Account..." />
    <Grid container component="main" maxWidth="xs" className="signin"
        sx={{
         height: '100vh' ,
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
        //  backgroundImage: 'url(https://pre00.deviantart.net/d4a7/th/pre/i/2013/276/9/8/mountain_background_with_waterfall_by_burtn-d6p1keb.jpg)',
        //  backgroundRepeat: 'no-repeat',         
         backgroundSize: 'cover',
        backgroundPosition: 'center',
       }}
        >
      <CssBaseline />
      <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      <Card sx={{
            maxWidth: 450,
           
           
          }}>
        <CardContent  sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          
          }}>
          {(auth.token && currentUser) && <Alert  severity="success">{"Account created successfully, contact your admin."}</Alert>}
          
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container>
            </Grid>
            {(error || auth.error) && <Alert  severity="error">{error || auth.error}</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              disabled={loading}
            >
              Sign Up With Google
            </Button>
            <Grid container>
              <Grid item>
                <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/login">Log In</Link>
              </div>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      </Box>
      </Grid>
      </ThemeProvider>
  )
}


