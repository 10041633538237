import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';



const Layout =(props) => {
 
 
    return (
    	<React.Fragment>
        <CssBaseline />
            {props.children}
      </React.Fragment>
    );
}


export default Layout;
