import SettingsIcon from '@mui/icons-material/Settings';
import PublicIcon from '@mui/icons-material/Public';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';


const categories = [
    {
        id: 'Apps',
        children: [
            {
            id: 'DFS Mon',
            icon: <PublicIcon />,
            active: false,
            link: '/dfs-mon/devices',
            childrenChild:[{id: 'Devices', link: '/dfs-mon/devices', active: false,}, {id: 'Hits', link: '/dfs-mon/hits', active: false,},{id: 'History', link: '/dfs-mon/history', active: false,}]
            },
            { id: 'SSID Mon',
            icon: <PermMediaOutlinedIcon />,
            link: '/ssid-mon/devices',
            active: false,
            childrenChild:[{id: 'Devices', link: '/ssid-mon/devices', active: false,},{id: 'History', link: '/ssid-mon/history', active: false,},{id: 'Config Errors', link: '/ssid-mon/config',  active: false}] 
            },
            { id: 'Ethernet Mon',
            icon: <SettingsEthernetIcon />, 
            link: '/ethernet-mon/ap', active: false, 
            childrenChild:[{id: "AP's", link: '/ethernet-mon/ap', active: false,}, {id: 'Stations', link: '/ethernet-mon/stations', active: false,}]
            },
            { id: 'Frequency Map',
            icon: <SettingsEthernetIcon />, 
            link: '/frequency-map', active: false, 
            childrenChild:[{id: "Map", link: '/frequency-map', active: false,}]
            },
            // { id: 'AIRTime Mon', 
            // icon: <PhonelinkSetupIcon />, 
            // link: '/airtime-mon', active: false,
            // childrenChild:[{id: 'New Customer', link: '/customers/new_customer', active: false,}, {id: 'All Customers', link: '/customers/all_customers', active: false,}] 
            // },
            // { id: 'Power Mon', 
            // icon: <SettingsInputComponentIcon />,  
            // link: '/power-mon', active: false,
            // childrenChild:[{id: 'New Customer', link: '/customers/new_customer', active: false,}, {id: 'All Customers', link: '/customers/all_customers', active: false,}] 
            // },
        ],
    },
    {
    id: 'Setting',
    children: [
        { id: 'General', icon: <SettingsIcon />,  link: '/general',
        childrenChild:[{id: 'Users', link: '/setting/users'}, {id: 'Company', link: '/setting/company'}] 
        },
    ],
    },
];


  export default categories;