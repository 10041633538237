import React, {useState} from 'react';
import { Link} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import categories from './NavSetting'



const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const initialFeedback = {"Devices": false, "History": false, "Configuration": false, "DFS Mon": false, "SSID Mon": false}

export default function Navigator(props) {
  const {handleClick, ...other } = props;
  const [expanded, setExpanded] = useState(false);
  const [activeMenu, setActiveMenu] =useState({...initialFeedback})

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 
  const handleActiveMenuItem = (event) => {
    setActiveMenu({...initialFeedback, [event.target.textContent]:true})
  }
  



  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
        WISP Hero
        </ListItem>
        <ListItem  component={Link} to="/"  sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText >Dashboard</ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({id: childId, icon, active,link,childrenChild }) => (
              <Box key={childId} >
              <Accordion sx={{bgcolor: '#101F33'}} expanded={expanded === childId} onChange={handleChange(childId)}>
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                <ListItem disablePadding key={childId}>
                  <ListItemButton onClick={handleActiveMenuItem}  selected={activeMenu[childId]} component={Link} to={link} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                  {childrenChild.map(({ id: childId, link}) => (
                    <Box key={link}>
                      <ListItem disablePadding key={link+childId}>
                        <ListItemButton onClick={handleActiveMenuItem}  component={Link} to={link} selected={activeMenu[childId]} sx={item}>
                          <ListItemText>{childId}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion> 
              </Box>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}