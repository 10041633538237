import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'fixed',
  zIndex: 1,
  width: '100%',
  height: '100%',
  margin: "auto",
  paddingTop: "200px",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color:'white',
  fontSize:12

};


export default function CustomModal({open, msg}) {


  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={style}
      >
        <Box >
          <Typography variant="h5" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          {msg}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
