import React,{ useEffect, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getSsidmondevs, restartSsidmondevs} from '../../store/actions/ssidmonActions';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeviceSettingDialog from '../deviceSetting';



const columns = [
  {
    field: 'siteName',
    headerName: 'Site Name',
    width: 150,
    editable: true,
  },
  {
    field: 'name',
    headerName: 'Device Name',
    width: 150,
    editable: true,
  },
  {
    field: 'activeSSID',
    headerName: 'Active SSID',
    width: 230,
  },
  {
    field: 'primarySSID',
    headerName: 'Prim SSID',
    width: 230,
  },
  {
    field: 'secondarySSID',
    headerName: 'Secon SSID',
    width: 160,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 100,
  },
  {
    field: "ipAddress",
    headerName: "IP Address",
    sortable: false,
    width: 180,
    renderCell: (params) => {
      const onClick = (e) => {
      
        e.stopPropagation(); 
      };

      return <Link href={`http://${params.row.ipAddress}`} rel="noopener noreferrer" target="_blank" underline="none" onClick={onClick} >{params.row.ipAddress}</Link>;
    }
  },
  {
    field: 'enabled',
    headerName: 'Status',
    description: 'Status',
    sortable: true,
    sortingOrder:['asc'],
    width: 120,
    valueGetter: (params) =>
    `${params.row.activeSSID === params.row.primarySSID? "Primary": "Secondary"}`,
  },
  {
    field: 'enabled',
    headerName: 'Status',
    description: 'Status',
    sortable: true,
    sortingOrder:['asc'],
    width: 120,
    renderCell: (params) => {
      return  ((params.row.activeSSID === params.row.primarySSID) && (params.row.secondarySSID !== params.row.primarySSID)) ?  <CheckCircleIcon color="primary" /> : <ErrorIcon color="secondary" />  ;

    }
  },
  // {
  //   field: "action",
  //   headerName: "Action",
  //   sortable: false,
  //   renderCell: (params) => {
  //     const onClick = (e) => {
  //       e.stopPropagation(); 
  //     };

  //     return <Button disabled={((params.row.activeSSID === params.row.primarySSID) && (params.row.secondarySSID !== params.row.primarySSID))} onClick={onClick}>Reboot</Button>;
  //   }
  // },
];




export default function AllDfsHits() {
  
  const ssidmondevs = useSelector((state) => state.ssidmon.ssidmonDevices) || {}
  const devices = ssidmondevs.filter(device => !((device.activeSSID === device.primarySSID) && (device.activeSSID === device.secondarySSID)) )
  const dispatch = useDispatch()
  const [selectedDeives, setSelectedDevice] = useState([])
  const [openDeviceSetting, setOpenDeviceSetting] = useState(false);

  const onCellSelect = (params) => {
    setSelectedDevice([...params])
  }

  const onRebookAll = () => {
    if(selectedDeives.length >0) {
      return  setOpenDeviceSetting(true)
    }
   return
  }

  const handleFeedBack = () => {
    setOpenDeviceSetting(false)
  }
  
  const handleConfirm = () => {
    setOpenDeviceSetting(false)
    return  dispatch(restartSsidmondevs(selectedDeives))

  }


  useEffect(() => {
    dispatch(getSsidmondevs())
    const interval = setInterval(() => {
       dispatch(getSsidmondevs())
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
    <Button variant="contained" style={{marginRight: "30px", float: "left",}} onClick={onRebookAll}>Reboot</Button>
    <Alert severity="info">Last Refreshed @ { devices.length > 0 && moment(devices[0].updatedAt).format('MMMM Do YYYY, h:mm:ss a')} -------- Next Refresh @ {devices.length > 0 && moment(moment(devices[0].updatedAt)).add(15, 'minutes').format('MMMM Do YYYY, h:mm:ss a')}</Alert>
    <DeviceSettingDialog openDeviceSetting={openDeviceSetting} msgType="error" handleFeedBack={handleFeedBack} question="Do you want to reboot selected devices?" handleConfirmFunc={handleConfirm}  />
    <div style={{ height: 450, paddingTop: "10px", width: '100%' }}>
      <DataGrid
        rows={devices}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25, 50, 100]}
        pStationsSize={10}
        rowsPerPStationsOptions={[100]}
        checkboxSelection
        disableSelectionOnClick
        autoPageSize={false}
        onSelectionModelChange={onCellSelect}
        loading={devices.length > 0? false : true}
      
      />
    </div>
    </React.Fragment>
  );
}
