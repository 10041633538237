import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'


const initialReceiptState = {
    companies:[],
    error: '',
    loading: false,
    feedback:'',
    isActionStart: false,
    actionMessage:'',
    displayFeeed: false
}


const actionFeedBack = (state, action) => {
    return updateObject(state, {
        actionMessage: action.message,
        displayFeeed: true,
        isActionStart: false
    })
}

const actionClearData = (state, action) => {
    return updateObject(state, initialReceiptState)
}

const actionStart = (state, action) => {
    return updateObject(state, {
        actionMessage: action.message,
        isActionStart: true,
        displayFeeed: true
    })
}

const confirmActionFeedBack = (state, action) => {
    return updateObject(state, {
        actionMessage: "",
        displayFeeed: false,
        isActionStart: false
    })
}


const actionFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    })
}

const companySuccess = (state, action) => {
    return updateObject(state, {
        companies: action.companies,
        error: null,
        loading: false,
    })
}


const globalReducer = (state=initialReceiptState, action) => {
    switch(action.type) {
        case actionTypes.COMPANY_SUCCESS:
            return companySuccess(state, action)
        case actionTypes.AUTH_LOGOUT:
                return actionClearData(state, action)
        case actionTypes.COMPANY_FAIL:
            return actionFail(state, action)
        case actionTypes.ACTION_START:
            return actionStart(state, action)
        case actionTypes.ACTION_FEEDBACK:
            return actionFeedBack(state, action)
        case actionTypes.CONFIRM_ACTION_FEEDBACK:
            return confirmActionFeedBack(state, action)
        default:
            return state;
    }
}


export default globalReducer;