import * as actionTypes from '../actions/actionTypes';
import { actionFeedBack, actionStart } from '../actions/authActions';
import { updateObject } from '../utility';


const initialAuthState = {
    token: null,
    error: null,
    loading: false,
    isAuthenticated: false,
    user:{},
    uers:[],
    signupFeed: '',
    resetFeed: '',
    isActionStart: false,
    expirationDate: '',
    actionMessage:''
}

//export const Store = createContext(initialAuthState)

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        isActionStart: true
    })
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        isAuthenticated: action.user.isAuthenticated,
        user: action.user,
        token: action.user.token,
        isActionStart: false
    })
}

const usersSuccess = (state, action) => {
    return updateObject(state, {
        users: action.users,
        isActionStart: false
     
    })
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        isAuthenticated: false,
        token: null,
        isActionStart: false
    })
}


const authFeedBackReset = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
    })
}

const authActionStart = (state, action) => {
    return updateObject(state, {
        actionMessage: action.message,
        isActionStart: true
    })
}

const authActionFeedBack = (state, action) => {
    return updateObject(state, {
        actionMessage: action.message,
        isActionStart: false
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        isAuthenticated: false,
        token: null,
        user: {},
        loading: false,
        expirationDate:''
    })
}

const authSignup = (state, action) => {
    return updateObject(state, {
        signupFeed: action.feedBack,
        isActionStart: false
    })
}

const authReset = (state, action) => {
    return updateObject(state, {
        resetFeed: action.resetFeed
    })
}

const updateUser = (state, action) => {
    return updateObject(state, {
        statusFeed: action.updateFeed,
        isActionStart: false
    })
}


const resetFeedback = (state, action) => {
    return updateObject(state, {
        updateFeed: '',
        resetFeed: '',
        signupFeed: ''
    })
}


const authReducer = (state=initialAuthState, action) => {
    switch(action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action)
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action)
        case actionTypes.AUTH_FAIL:
            return authFail(state, action)
        case actionTypes.ACTION_START:
            return authActionStart(state, action)
        case actionTypes.ACTION_FEEDBACK:
            return authActionFeedBack(state, action)
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action)
        case actionTypes.AUTH_SIGNUP:
            return authSignup(state, action)
        case actionTypes.AUTH_RESET_SUCCESS:
            return authReset(state, action)
        case actionTypes.RESET_FEEDBACK:
            return resetFeedback(state, action)
        case actionTypes.AUTH_FEEDBACK_RESET:
            return authFeedBackReset(state, action)
        case actionTypes.AUTH_STATUS_SUCCESS:
            return updateUser(state, action)
        case actionTypes.AUTH_USERS_SUCCESS:
            return usersSuccess(state, action)
        default:
            return state;
    }
}




//export {initialAuthState, authReducer};

export default authReducer;