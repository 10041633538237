import React  from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button} from '@mui/material';
import Alert from '@mui/material/Alert';


const DeviceSettingDialog = ({ openDeviceSetting,msgType, handleFeedBack, question, handleConfirmFunc }) => {

    const handleComfirm = () => {
        handleConfirmFunc()
    }

    const onClose = () => {
        handleFeedBack()
    }


    return (
        <Dialog  open={openDeviceSetting} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Comfirm</DialogTitle>
            <DialogContent>
                <Alert severity={msgType ==='info'? "info" : "error"}>{question}</Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleComfirm} color="primary">
                    Yes
                </Button>
                <Button onClick={onClose} color="secondary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeviceSettingDialog;