import authReducer from './authReducer';
import deviceReducer from './deviceReducer';
import ssidmonReducer from './ssidmonReducer';
import globalReducer from './globalReducer';
import {firebaseReducer} from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";
import { combineReducers } from 'redux';


const rootReducer = combineReducers({
    auth: authReducer,
    device: deviceReducer,
    ssidmon: ssidmonReducer,
    global:globalReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
})


export default rootReducer;